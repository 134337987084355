var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as seo from '@/router/seo';
import store, { loadListPropertyNew, setMetaInfo } from '@/store';
import * as types from '@/types';
import api, { getPageImageURL } from '@/api';
import PageComponent from '@/lib/page-component';
export default PageComponent.extend({
    data: function () {
        return {
            listPropertyData: null,
            listYourPropertyForm: {
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                streetAddress: "",
                cityStateZip: "",
                ownsHome: false,
                lookingToPurchase: false,
                referrer: "",
                recaptcha: "",
                details: "",
                validationErrors: {}
            },
            howMuchYouCanEarnForm: {
                firstName: "",
                email: "",
                recaptcha: "",
                validationErrors: {}
            },
            formNumber: null,
            canSubmit: true,
        };
    },
    methods: {
        getTinyImage: function (pageId) {
            return getPageImageURL(pageId, types.ImageSize.tiny);
        },
        getSmallImage: function (pageId) {
            return getPageImageURL(pageId, types.ImageSize.small);
        },
        getMediumImage: function (pageId) {
            return getPageImageURL(pageId, types.ImageSize.medium);
        },
        getLargeImage: function (pageId) {
            return getPageImageURL(pageId, types.ImageSize.large);
        },
        getExtraLargeImage: function (pageId) {
            return getPageImageURL(pageId, types.ImageSize.extra_large);
        },
        setImage: function (pageId) {
            return "background-image: url('".concat(this.getExtraLargeImage(pageId), "')");
        },
        setOwnsHome: function () {
            this.$data.listYourPropertyForm.ownsHome = !this.$data.listYourPropertyForm.ownsHome;
        },
        setLookingToPurchase: function () {
            this.$data.listYourPropertyForm.lookingToPurchase = !this.$data.listYourPropertyForm.lookingToPurchase;
        },
        scrollToForm: function (formNumber) {
            var el = formNumber === 1 ? this.$refs.topForm : this.$refs.bottomForm;
            if (el) {
                // @ts-ignore
                el.scrollIntoView({
                    block: 'center',
                    behavior: 'smooth'
                });
            }
        },
        validateFirstForm: function (formNumber) {
            var _this = this;
            var errors = {};
            if (formNumber == 1) {
                errors.firstName = this.$data.listYourPropertyForm.firstName.trim() == "";
                errors.email = this.$data.listYourPropertyForm.email.trim() == "";
                errors.lastName = this.$data.listYourPropertyForm.lastName.trim() == "";
                errors.phone = this.$data.listYourPropertyForm.phone.trim() == "";
                errors.streetAddress = this.$data.listYourPropertyForm.streetAddress.trim() == "";
                errors.cityStateZip = this.$data.listYourPropertyForm.cityStateZip.trim() == "";
                errors.referrer = this.$data.listYourPropertyForm.referrer.trim() == "";
                this.$data.listYourPropertyForm.validationErrors = errors;
            }
            else {
                errors.firstName = this.$data.howMuchYouCanEarnForm.firstName.trim() == "";
                errors.email = this.$data.howMuchYouCanEarnForm.email.trim() == "";
                this.$data.howMuchYouCanEarnForm.validationErrors = errors;
            }
            if (errors.firstName || errors.lastName || errors.phone ||
                errors.email || errors.streetAddress || errors.cityStateZip ||
                errors.referrer) {
                return false;
            }
            //this.$data.SubmitBtnText = "Please wait...";
            this.$data.canSubmit = false;
            setTimeout(function () {
                _this.$data.canSubmit = true;
            }, 5000);
            return true;
        },
        submitFirstForm: function (formNumber) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.$data.formNumber = formNumber;
                    if (this.validateFirstForm(formNumber)) {
                        if (formNumber === 1) {
                            // @ts-ignore
                            this.$refs.invisibleRecaptchaContact.execute();
                        }
                        else {
                            // @ts-ignore
                            this.$refs.invisibleRecaptchaContact2.execute();
                        }
                    }
                    return [2 /*return*/];
                });
            });
        },
        formRecaptchaCallback: function (recaptchaToken) {
            return __awaiter(this, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            data = this.$data.formNumber == 1 ?
                                this.$data.listYourPropertyForm : this.$data.howMuchYouCanEarnForm;
                            data.recaptcha = recaptchaToken;
                            data.formNumber = this.formNumber;
                            this.$data.canSubmit = true;
                            return [4 /*yield*/, api.listYourPropertyNewForm(data)];
                        case 1:
                            _a.sent();
                            this.$data.listYourPropertyForm.firstName = "";
                            this.$data.listYourPropertyForm.lastName = "";
                            this.$data.listYourPropertyForm.email = "";
                            this.$data.listYourPropertyForm.phone = "";
                            this.$data.listYourPropertyForm.streetAddress = "";
                            this.$data.listYourPropertyForm.cityStateZip = "";
                            this.$data.listYourPropertyForm.ownsHome = false;
                            this.$data.listYourPropertyForm.lookingToPurchase = false;
                            this.$data.listYourPropertyForm.referrer = "";
                            this.$data.listYourPropertyForm.details = "";
                            this.$data.howMuchYouCanEarnForm.firstName = "";
                            this.$data.howMuchYouCanEarnForm.email = "";
                            alert("Thank you for your inquiry. We will be in touch shortly.");
                            return [2 /*return*/];
                    }
                });
            });
        },
        updateHeader: function () {
            return __awaiter(this, void 0, void 0, function () {
                var metaInfoData;
                return __generator(this, function (_a) {
                    this.setTitle("");
                    this.setBackgroundLink('');
                    this.setBreadcrumbs([]);
                    metaInfoData = {
                        title: this.listPropertyData.meta_title,
                        meta: [{
                                vmid: 'description',
                                name: 'description',
                                content: this.listPropertyData.meta_description
                            }, {
                                vmid: 'keywords',
                                name: 'keywords',
                                content: this.listPropertyData.meta_keywords
                            }],
                        script: seo.getLdJson(seo.getDefaultPageLocation())
                    };
                    setMetaInfo(store, metaInfoData);
                    return [2 /*return*/];
                });
            });
        },
    },
    mounted: function () {
        var _this = this;
        // @ts-ignore
        setTimeout(function () {
            _this.updateHeader();
        }, 100);
    },
    computed: {},
    beforeRouteEnter: function (to, from, next) {
        return __awaiter(this, void 0, void 0, function () {
            var listPropertyData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, loadListPropertyNew(store)];
                    case 1:
                        listPropertyData = _a.sent();
                        next(function (vm) {
                            vm.$data.listPropertyData = listPropertyData;
                        });
                        return [2 /*return*/];
                }
            });
        });
    },
});
