var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
// import homepageData from '@/data/homepage'
import { apiRoot, imageRoot, siteName, platform_apiRoot } from './config';
import * as types from '@/types';
import { parseListing } from '@/lib/property';
import store from '@/store';
export function getImageURL(image, size) {
    if (size === void 0) { size = types.ImageSize.medium; }
    return "".concat(imageRoot).concat(image.listingId, "/").concat(size, "/").concat(image.fileName);
}
export function getPageImageURL(pageID, size) {
    if (size === void 0) { size = types.ImageSize.medium; }
    var tempSiteName = siteName;
    if (siteName.includes("nomadness")) {
        tempSiteName = 'nomadness';
    }
    return "".concat(imageRoot).concat(tempSiteName, "/").concat(pageID, "/").concat(size, "/1.jpg");
}
export function getSpecificPageImageURL(pageID, size, imgNumber) {
    if (size === void 0) { size = types.ImageSize.medium; }
    if (imgNumber === void 0) { imgNumber = 2; }
    var tempSiteName = siteName;
    if (siteName.includes("nomadness")) {
        tempSiteName = 'nomadness';
    }
    return "".concat(imageRoot).concat(tempSiteName, "/").concat(pageID, "/").concat(size, "/").concat(imgNumber, ".jpg");
}
export function getPageAttachmentURL(name) {
    var tempSiteName = siteName;
    if (siteName.includes("nomadness")) {
        tempSiteName = 'nomadness';
    }
    return "".concat(imageRoot).concat(tempSiteName, "/").concat(name);
}
var m_isMobile = undefined;
export function isMobile() {
    if (typeof m_isMobile === 'undefined') {
        var check_1 = false;
        (function (a) {
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)))
                check_1 = true;
            // @ts-ignore
        })(navigator.userAgent || navigator.vendor || window.opera);
        m_isMobile = check_1;
    }
    return m_isMobile;
}
export function preloadImage(url) {
    return new Promise(function (resolve, reject) {
        var img = new Image();
        img.src = url;
        img.onload = function () {
            resolve(url);
        };
    });
}
var listingAvailability = null;
var api = axios.create({
    baseURL: apiRoot
});
var platform_api = axios.create({
    baseURL: platform_apiRoot
});
var lib = {
    location: function (id) {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get("".concat(siteName, "/locations/").concat(id, ".gz.json"))];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    },
    listYourPropertyNewForm: function (formData) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, platform_api.post('/res/api/listYourPropertyNewForm.cfm', formData)];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    },
    locationActivity: function (locationID, id) {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get("".concat(siteName, "/locations/").concat(locationID, "/").concat(id, ".gz.json"))];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    },
    locations: function () {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get("".concat(siteName, "/locations/index.gz.json"))];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    },
    blogPosts: function () {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get("".concat(siteName, "/blog.gz.json"))];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    },
    blogPost: function (id) {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get("".concat(siteName, "/blog/").concat(id, ".gz.json"))];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    },
    conciergeServices: function () {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get("".concat(siteName, "/concierge-services.gz.json"))];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    },
    wbListingCategories: function (listingNum) {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get("".concat(siteName, "/welcomebook/categories-").concat(listingNum, ".gz.json"))];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    },
    wbLocationCategories: function (location) {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get("".concat(siteName, "/welcomebook/categories-location-").concat(location, ".gz.json"))];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    },
    wbGlobalCategories: function () {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get("".concat(siteName, "/welcomebook/categories-global.gz.json"))];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    },
    wbListingTopics: function (listingNum) {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get("".concat(siteName, "/welcomebook/topics-").concat(listingNum, ".gz.json"))];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    },
    wbLocationTopics: function (location) {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get("".concat(siteName, "/welcomebook/topics-location-").concat(location, ".gz.json"))];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    },
    wbGlobalTopics: function () {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get("".concat(siteName, "/welcomebook/topics-global.gz.json"))];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    },
    // listings are shared - no sitename here
    listingsAvailability: function () {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(listingAvailability == null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, api.get("listings/availability.gz.json?_=".concat(new Date().getTime()))];
                    case 1:
                        // force availability reload
                        listingAvailability = (_a.sent()).data;
                        _a.label = 2;
                    case 2: return [2 /*return*/, listingAvailability];
                }
            });
        });
    },
    // listings are shared - no sitename here
    listings: function () {
        return __awaiter(this, void 0, Promise, function () {
            var listingsRaw, isTestMode, listingAvailability, a, m_availabilityFirstDate, listings;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get("listings/index.gz.json")];
                    case 1:
                        listingsRaw = (_a.sent()).data;
                        isTestMode = store.state.isTestMode;
                        listingsRaw = listingsRaw.filter(function (l) { return l.isTestProperty == isTestMode; });
                        return [4 /*yield*/, lib.listingsAvailability()];
                    case 2:
                        listingAvailability = _a.sent();
                        a = listingAvailability['@'];
                        m_availabilityFirstDate = new Date(parseInt(a.substr(0, 4)), parseInt(a.substr(5, 2)) - 1, parseInt(a.substr(8, 2)));
                        m_availabilityFirstDate.setHours(0, 0, 0, 0);
                        listingsRaw = listingsRaw.map(function (l) {
                            l.availability = listingAvailability[l.id];
                            l.availabilityFirstDate = m_availabilityFirstDate;
                            l.rawBlackouts = listingAvailability.blackoutDates;
                            return l;
                        });
                        listings = listingsRaw.map(parseListing);
                        if (siteName == 'mrbo') {
                            return [2 /*return*/, listings.filter(function (x) { return x.locationid == 1; })];
                        }
                        if (siteName == 'steam') {
                            return [2 /*return*/, listings.filter(function (x) { return x.locationid == 8; })];
                        }
                        return [2 /*return*/, listings];
                }
            });
        });
    },
    // listings are shared - no sitename here
    listing: function (id) {
        return __awaiter(this, void 0, Promise, function () {
            var listingRaw, images, listingAvailability, a, listing;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get("listings/".concat(id, ".gz.json?_=").concat(new Date().getTime()))];
                    case 1:
                        listingRaw = (_a.sent()).data;
                        return [4 /*yield*/, api.get("".concat(imageRoot).concat(id, "/index.json?_=").concat(new Date().getTime()))];
                    case 2:
                        images = (_a.sent()).data;
                        return [4 /*yield*/, lib.listingsAvailability()];
                    case 3:
                        listingAvailability = _a.sent();
                        listingRaw.images = images;
                        listingRaw.availability = listingAvailability[listingRaw.id];
                        a = listingAvailability['@'];
                        listingRaw.availabilityFirstDate = new Date(parseInt(a.substr(0, 4)), parseInt(a.substr(5, 2)) - 1, parseInt(a.substr(8, 2)));
                        listingRaw.rawBlackouts = listingAvailability.blackoutDates;
                        if (!listingRaw.isTestProperty == store.state.isTestMode) {
                            return [2 /*return*/];
                        }
                        listing = parseListing(listingRaw);
                        return [2 /*return*/, listing];
                }
            });
        });
    },
    resInfo: function (pubResId) {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, platform_api.get("res/api/ResInfo.cfm?PubResId=".concat(pubResId))];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    },
    // listings are shared - no sitename here (not so sure about the reviews ...)
    reviews: function (id) {
        return __awaiter(this, void 0, Promise, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get("listings/".concat(id, "-reviews.gz.json"))];
                    case 1:
                        result = (_a.sent()).data;
                        try {
                            return [2 /*return*/, result.filter(function (x) { return x.rating >= 1 && x.rating <= 5; })];
                        }
                        catch (e) {
                            return [2 /*return*/];
                        }
                        return [2 /*return*/];
                }
            });
        });
    },
    // listings are shared - no sitename here
    discount: function (id) {
        return __awaiter(this, void 0, Promise, function () {
            var discount, listings;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get("".concat(siteName, "/discounts/").concat(id, ".gz.json"))];
                    case 1:
                        discount = (_a.sent()).data;
                        return [4 /*yield*/, lib.listings()];
                    case 2:
                        listings = _a.sent();
                        discount[0].listings = discount[0].listingIDs
                            .map(function (listing_id_str) {
                            var listing_id = parseInt(listing_id_str);
                            var ll = listings.find(function (x) { return x.id == listing_id; });
                            if (ll == null)
                                return null;
                            var listing = JSON.parse(JSON.stringify(ll));
                            listing.thumbnail = ll.thumbnail;
                            listing.mainImage = ll.mainImage;
                            if (listing)
                                listing.options = {
                                    featured: false,
                                    discounted: false,
                                    petsAllowed: listing.options.petsAllowed
                                };
                            return listing;
                        })
                            .filter(function (x) { return x != null; });
                        discount[0].listings.sort(function () {
                            return 0.5 - Math.random();
                        });
                        discount[0].listings.sort(function (a, b) {
                            return a.priority - b.priority;
                        });
                        return [2 /*return*/, discount[0]];
                }
            });
        });
    },
    homepage: function () {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get("".concat(siteName, "/pages/home.gz.json"))];
                    case 1:
                        data = (_a.sent()).data;
                        data.testimonials = data.subPages;
                        // data.testimonials = (data as any).subPages.map((page:homepageTypes.Testimonial, index:number) => {
                        //   //let t = homepageData.testimonials[index]
                        //   //page.image = t.image
                        //   return page
                        // })
                        // TODO : populate from listings
                        //data.featured_properties = homepageData.featured_properties
                        return [2 /*return*/, data];
                }
            });
        });
    },
    aboutpage: function () {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get("".concat(siteName, "/pages/about.gz.json"))];
                    case 1:
                        data = (_a.sent()).data;
                        data.team = data.subPages.map(function (page, index) {
                            //let t = homepageData.team[index]
                            //page.image = t.image
                            return page;
                        });
                        return [2 /*return*/, data];
                }
            });
        });
    },
    listpropertypage: function () {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get("".concat(siteName, "/pages/listproperty.gz.json"))];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    },
    listpropertyNew: function () {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get("".concat(siteName, "/pages/listpropertynew.gz.json"))];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    },
    discounts: function () {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get("".concat(siteName, "/pages/discounts.gz.json"))];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    },
    newProperties: function () {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get("".concat(siteName, "/pages/properties-new.gz.json"))];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    },
    careerpage: function () {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get("".concat(siteName, "/pages/career.gz.json"))];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    },
    contactpage: function () {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get("".concat(siteName, "/pages/contact.gz.json"))];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    },
    agents: function () {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get("listings/agents.gz.json")];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    },
    referrers: function () {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get("listings/referrers.gz.json")];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    },
    countries: function () {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get("listings/countries.gz.json")];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    },
    loadTermsOfRentals: function (listing_pid, pub_resid) {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, platform_api.get("res/Panels/terms.html?qListingId=".concat(listing_pid, "&PubResID=").concat(pub_resid))];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    },
    loadCancellationPolicy: function (listing_pid, pub_resid) {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, platform_api.get("res/Panels/CancellationPolicy.html?qListingId=".concat(listing_pid, "&PubResID=").concat(pub_resid))];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    },
    res_discounts: function (listing_pid, arrive, depart) {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, platform_api.get("res/api/GetAllValidDiscounts.cfm?Arrive=".concat(escape(this.formatDateYMD(arrive)), "&Depart=").concat(escape(this.formatDateYMD(depart)), "&ListingId=").concat(listing_pid))];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    },
    validate_availability: function (listing_pid, arrive, depart, adults, children, pets, discount_code) {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, platform_api.get("res/lib/ajax/CheckAvail.html?qListingId=".concat(listing_pid, "&qArrive=").concat(escape(this.formatDateYMD(arrive)), "&qDepart=").concat(escape(this.formatDateYMD(depart)), "&qAdults=").concat(adults, "&qChildren=").concat(children, "&qPets=").concat(pets, "&qDCode=").concat(discount_code))];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data.split('|')];
                }
            });
        });
    },
    // async discount_details (listing_pid: number, arrive: Date, depart: Date, discount_code: string):  Promise<types.DiscountCode> {
    //   let data = (await platform_api.get(`res/api/GetDiscountDetails.cfm?ListingId=${listing_pid}&Arrive=${escape(this.formatDateYMD(arrive))}&Depart=${escape(this.formatDateYMD(depart))}&DiscountCode=${discount_code}`)).data as types.DiscountCode
    //   return data
    // },
    get_quote: function (listing_pid, arrive, depart, adults, children, pets, discount_code, insurance) {
        return __awaiter(this, void 0, Promise, function () {
            var api_url, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        api_url = "res/api/Quote.cfm?ListingId=".concat(listing_pid, "&Arrive=").concat(escape(this.formatDateYMD(arrive)), "&Depart=").concat(escape(this.formatDateYMD(depart)), "&numberOfAdults=").concat(adults, "&numberOfChildren=").concat(children, "&numberOfPets=").concat(pets, "&DiscountCode=").concat(discount_code, "&insurance=").concat(insurance);
                        return [4 /*yield*/, platform_api.get(api_url)];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    },
    book: function (data, ExpectedTotal) {
        return __awaiter(this, void 0, Promise, function () {
            var request_data, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!data.renter.agreeToTermsOfRental && !data.renter.agreeToCancelationPolicy && !data.renter.agreeToBackgroundCheck) {
                            return [2 /*return*/, null];
                        }
                        request_data = {
                            ListingId: data.Listing.pid,
                            Arrive: this.formatDateYMD(data.Arrive),
                            Depart: this.formatDateYMD(data.Depart),
                            Adults: data.Adults,
                            Children: data.Children,
                            Pets: data.NumPets,
                            Insurance: data.InsuranceCoverage,
                            DiscountCode: data.SelectedDiscount ? data.SelectedDiscount.code : '',
                            FirstName: data.renter.firstName,
                            LastName: data.renter.lastName,
                            DateOfBirth: this.formatDateYMD(data.renter.dateOfBirth),
                            Address: data.renter.address,
                            City: data.renter.city,
                            State: data.renter.state,
                            Zip: data.renter.zip,
                            Phone: data.renter.phone,
                            AltPhone: data.renter.altPhone,
                            Email: data.renter.email,
                            CountryID: data.renter.countryID,
                            AgentID: data.renter.agentID,
                            ReferrerID: data.renter.referrerID,
                            CardName: data.renter.billing_name,
                            CardNumber: data.renter.card_number,
                            CardExpMonth: data.renter.card_expMonth,
                            CardExpYear: data.renter.card_expYear,
                            CardCVV: data.renter.card_CVV,
                            BillingAddress: data.renter.billing_address,
                            BillingCity: data.renter.billing_city,
                            BillingState: data.renter.billing_state,
                            BillingZip: data.renter.billing_zip,
                            BillingCountryID: data.renter.billing_countryID,
                            ExpectedTotal: ExpectedTotal,
                            SubscribeToPromotions: data.renter.subscribeToPromotions,
                            VehicleMake: data.renter.vehicleMake,
                            VehicleModel: data.renter.vehicleModel,
                        };
                        return [4 /*yield*/, platform_api.post("res/api/Book.cfm", request_data)];
                    case 1:
                        result = (_a.sent()).data;
                        return [2 /*return*/, result];
                }
            });
        });
    },
    inquiry: function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var request_data, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request_data = {
                            ListingId: data.ListingId,
                            ListingNum: data.ListingNum,
                            ListingName: data.ListingName,
                            Name: data.Name,
                            Email: data.Email,
                            MessageText: data.MessageText,
                            Complex: data.Complex,
                            Pets: data.Pets,
                            Rooms: data.Rooms,
                            SendWeeklySpecials: data.SendWeeklySpecials,
                            RequestCopy: data.RequestCopy,
                            Arrive: this.formatDateYMD(data.Period.start),
                            Depart: this.formatDateYMD(data.Period.end),
                            Recaptcha: data.recaptcha
                        };
                        return [4 /*yield*/, platform_api.post("res/api/Inquiry.cfm", request_data)];
                    case 1:
                        result = (_a.sent()).data;
                        return [2 /*return*/, result];
                }
            });
        });
    },
    nomadCaptcha: function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, platform_api.get('/res/api/NomadCaptcha.cfm')];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    },
    subscribe: function (data) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, platform_api.post('/res/api/Subscribe.cfm', data)];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    },
    querymgmt: function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, platform_api.post("res/api/QueryMgmt.cfm", data)];
                    case 1:
                        result = (_a.sent()).data;
                        return [2 /*return*/, result];
                }
            });
        });
    },
    applyForJob: function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var params, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        params = data.files;
                        params.set('jobTitle', data.job.title);
                        params.set('department', data.departmentName);
                        params.set('location', data.locationName);
                        params.set('name', data.Name);
                        params.set('email', data.Email);
                        params.set('phone', data.Phone);
                        params.set('request_copy', data.RequestCopy);
                        params.set('recaptcha', data.recaptcha);
                        return [4 /*yield*/, platform_api.post("res/api/ApplyForJob.cfm", params, {
                                headers: { 'Content-Type': 'multipart/form-data' }
                            })];
                    case 1:
                        result = (_a.sent()).data;
                        return [2 /*return*/, result];
                }
            });
        });
    },
    review: function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var request_data, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request_data = {
                            ListingId: data.ListingId,
                            ListingNum: data.ListingNum,
                            ListingName: data.ListingName,
                            Title: data.Title,
                            ReviewText: data.ReviewText,
                            DateStayed: this.formatDateYMD(data.DateStayed),
                            Rating: data.Rating,
                            Name: data.Name,
                            Email: data.Email,
                            WhereILive: data.WhereILive,
                            SendWeeklySpecials: data.SendWeeklySpecials,
                            Recaptcha: data.recaptcha
                        };
                        return [4 /*yield*/, platform_api.post("res/api/Review.cfm", request_data)];
                    case 1:
                        result = (_a.sent()).data;
                        return [2 /*return*/, result];
                }
            });
        });
    },
    contact: function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var request_data, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request_data = {
                            Name: data.name,
                            Email: data.email,
                            Phone: data.phone,
                            MessageText: data.message,
                            SendWeeklySpecials: data.SendWeeklySpecials,
                            Recaptcha: data.recaptcha
                        };
                        return [4 /*yield*/, platform_api.post("res/api/Contact.cfm", request_data)];
                    case 1:
                        result = (_a.sent()).data;
                        return [2 /*return*/, result];
                }
            });
        });
    },
    elevateStay: function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var request_data, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request_data = {
                            FirstName: data.firstName,
                            LastName: data.lastName,
                            Phone: data.phone,
                            Email: data.email,
                            Destination: data.destination,
                            Interests: data.interests,
                            NeedToAdd: data.needToAdd,
                            Recaptcha: data.recaptcha,
                        };
                        return [4 /*yield*/, platform_api.post("res/api/ElevateStay.cfm", request_data)];
                    case 1:
                        result = (_a.sent()).data;
                        return [2 /*return*/, result];
                }
            });
        });
    },
    bookExperience: function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var request_data, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request_data = {
                            FirstName: data.firstName,
                            LastName: data.lastName,
                            Phone: data.phone,
                            Email: data.email,
                            AdditionalInformation: data.additionalInformation,
                            Destination: data.destination,
                            LodgingBookingStatus: data.lodgingBookingStatus,
                            Interest: data.interest,
                            PreferedDate: data.preferedDate,
                            PreferedTime: data.preferedTime,
                            Recaptcha: data.recaptcha,
                        };
                        return [4 /*yield*/, platform_api.post("res/api/BookExperience.cfm", request_data)];
                    case 1:
                        result = (_a.sent()).data;
                        return [2 /*return*/, result];
                }
            });
        });
    },
    lz2: function (n) {
        return ('0' + Math.floor(n)).slice(-2);
    },
    parseDate: function (date) {
        if (!date) {
            return undefined;
        }
        if (typeof date === 'string') {
            return this.strToDate(date);
        }
        return date;
    },
    strToDate: function (dStr) {
        if (dStr.indexOf(':') == -1)
            dStr += 'T00:00:00';
        if (dStr.endsWith('Z')) {
            dStr = dStr.substring(0, dStr.length - 1);
        }
        return new Date(dStr);
    },
    formatDate: function (date) {
        var d = this.parseDate(date);
        if (!d)
            return '';
        return "".concat(d.getMonth() + 1, "/").concat(d.getDate(), "/").concat(d.getFullYear());
    },
    formatDateYMD: function (date) {
        var d = this.parseDate(date);
        if (!d)
            return '';
        var month = d.getMonth() + 1 < 10 ? "0".concat(d.getMonth() + 1) : d.getMonth() + 1;
        var day = d.getDate() < 10 ? "0".concat(d.getDate()) : d.getDate();
        return "".concat(d.getFullYear(), "-").concat(month, "-").concat(day);
    }
};
export default lib;
