import searchBar from '@/components/search-bar.vue';
import api from '@/api';
import PageComponent from '@/lib/page-component';
export default PageComponent.extend({
    name: 'location',
    components: { searchBar: searchBar },
    props: ['id', 'slug'],
    data: function () {
        return {
            location: null,
        };
    },
    methods: {
        updateHeader: function () {
            if (!this.location) {
                return;
            }
            this.setTitle('');
            this.setBackgroundLink('');
            this.setBreadcrumbs([
                { label: 'Home', route: { name: 'home' } },
                { label: 'Properties' },
                { label: 'Rentals' },
            ]);
        },
    },
    watch: {
        'location': 'updateHeader'
    },
    beforeRouteEnter: function (to, from, next) {
        var id = parseInt(to.params.id, 10);
        api.location(id).then(function (location) {
            next(function (vm) { vm.$data.location = location; });
        });
    },
});
