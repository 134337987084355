var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
import api from '@/api';
import * as quoteLib from '@/lib/quote';
import { getStoreAccessors } from 'vuex-typescript';
import { addDays } from 'date-fns';
var state = {
    isLoading: false,
    loadingImgData: { url: '', title: '', isLoadingListing: false },
    isTestMode: false,
    title: '',
    backgroundLink: '',
    breadcrumbs: [],
    metaInfo: {},
    listings: [],
    listpropertypageNewData: undefined,
    locationDetails: {},
    locationActivities: {},
    discountDetails: {},
    discountsData: undefined,
    newPropertiesData: undefined,
    locations: undefined,
    homepageData: undefined,
    isHomepageCombined: false,
    aboutpageData: undefined,
    contactpageData: undefined,
    listpropertypageData: undefined,
    careerpageData: undefined,
    lastSearch: undefined,
    blogPosts: undefined,
    blogPost: undefined,
    conciergeServices: undefined,
    wbCategories: [],
};
var store = {
    namespaced: true,
    state: state,
    getters: {
        lastSearch: function (state) {
            return state.lastSearch;
        },
        location: function (state) {
            return function location(id) {
                for (var _i = 0, _a = state.locations.subPages; _i < _a.length; _i++) {
                    var l = _a[_i];
                    if (l.id === id) {
                        return l;
                    }
                }
                return null;
            };
        },
        listings: function (state) {
            return function listings(_a) {
                var LocationID = _a.LocationID, NumGuests = _a.NumGuests, Arrive = _a.Arrive, Depart = _a.Depart, Price = _a.Price, Bedrooms = _a.Bedrooms, complex = _a.complex, property_type = _a.property_type, seasonal = _a.seasonal, isShortTerm = _a.isShortTerm, isLongTerm = _a.isLongTerm, isDiscountsSearch = _a.isDiscountsSearch, min_stay = _a.min_stay, pool = _a.pool, spa = _a.spa, wifi = _a.wifi, ac = _a.ac, pets = _a.pets, golf = _a.golf, gondola = _a.gondola, garage = _a.garage, IsEcoFriendly = _a.IsEcoFriendly, village = _a.village, fishing = _a.fishing, ski = _a.ski, sortby = _a.sortby;
                var today = new Date(new Date().setHours(0, 0, 0, 0));
                var todayAsTime = today.getTime();
                var result = state.listings.filter(function (Listing) {
                    if (LocationID !== 0 && Listing.locationid !== LocationID) {
                        return false;
                    }
                    if (Listing.rooms.sleeps < NumGuests) {
                        return false;
                    }
                    if (typeof Price !== 'undefined' && Listing.options.propertyTypePrice != Price) {
                        return false;
                    }
                    if (typeof Bedrooms !== 'undefined') {
                        //@ts-ignore
                        var numBedrooms = parseInt(Bedrooms);
                        if (numBedrooms == 5) {
                            if (Listing.rooms.bedrooms < 4.5)
                                return false;
                        }
                        else if (!(Listing.rooms.bedrooms >= numBedrooms - 0.6 && Listing.rooms.bedrooms <= numBedrooms + 0.6)) {
                            return false;
                        }
                    }
                    if (typeof complex !== 'undefined' && Listing.options.complexName.toLowerCase().trim() != complex.toLowerCase()) {
                        return false;
                    }
                    if (typeof property_type !== 'undefined' && Listing.options.propertyTypeID != property_type) {
                        return false;
                    }
                    if (typeof seasonal !== 'undefined' && Listing.options.isSeasonal != seasonal) {
                        return false;
                    }
                    if (typeof isShortTerm !== 'undefined' && Listing.options.isShortTerm != isShortTerm) {
                        return false;
                    }
                    if (typeof isLongTerm !== 'undefined' && Listing.options.isLongTerm != isLongTerm) {
                        return false;
                    }
                    var hasLastMinuteDiscount;
                    if (Arrive && Depart) {
                        var arriveTime_1 = api.parseDate(Arrive).getTime();
                        var departTime_1 = api.parseDate(Depart).getTime();
                        hasLastMinuteDiscount = Listing.discounts.some(function (d) { return quoteLib.IsLastMinuteDiscountAppliable(d, Listing, arriveTime_1, departTime_1); });
                    }
                    else {
                        hasLastMinuteDiscount = Listing.discounts.some(function (d) { return d.isLastMinute && d.percent; });
                    }
                    if (typeof isDiscountsSearch !== 'undefined' && !hasLastMinuteDiscount) {
                        return false;
                    }
                    if (typeof pool !== 'undefined' && !Listing.amenities.includes('pool')) {
                        return false;
                    }
                    if (typeof spa !== 'undefined' && !Listing.amenities.includes('spa')) {
                        return false;
                    }
                    if (typeof wifi !== 'undefined' && !Listing.amenities.includes('wifi')) {
                        return false;
                    }
                    if (typeof ac !== 'undefined' && !Listing.amenities.includes('a_c')) {
                        return false;
                    }
                    if (typeof pets !== 'undefined' && !Listing.amenities.includes('pets')) {
                        return false;
                    }
                    if (typeof ski !== 'undefined' && !Listing.amenities.includes('ski')) {
                        return false;
                    }
                    if (typeof golf !== 'undefined' && !Listing.amenities.includes('golf')) {
                        return false;
                    }
                    if (typeof gondola !== 'undefined' && !Listing.amenities.includes('gondola')) {
                        return false;
                    }
                    if (typeof garage !== 'undefined' && !Listing.amenities.includes('garage')) {
                        return false;
                    }
                    if (typeof IsEcoFriendly !== 'undefined' && !Listing.amenities.includes('IsEcoFriendly')) {
                        return false;
                    }
                    if (typeof fishing !== 'undefined' && !Listing.amenities.includes('fishing')) {
                        return false;
                    }
                    if (typeof village !== 'undefined' && !Listing.amenities.includes('village')) {
                        return false;
                    }
                    if (Arrive && Depart && !quoteLib.IsAvailable(Listing, Arrive, Depart)) {
                        return false;
                    }
                    if (typeof min_stay !== 'undefined' && quoteLib.getMinStay(Listing, Arrive, Depart) != min_stay) {
                        return false;
                    }
                    return true;
                });
                var noQuotePrice = sortby == 'price-descending' ? -1 : 9999999; // so we can sort easier
                var reservationDays = [];
                var d = Arrive;
                while (d < Depart) {
                    reservationDays.push(api.formatDateYMD(d));
                    d = addDays(d, +1);
                }
                result.forEach(function (Listing) {
                    if (Listing.options.allowBookingFromWebsite && Arrive && Depart) {
                        // const availableDiscounts = [] as types.Discount[]; // temporarily disabled
                        // const availableDiscounts = state.discountsData.discounts.filter(
                        //   (discount) =>
                        //     api.parseDate(discount.from_date).getTime() <= api.parseDate(Depart).getTime() &&
                        //     api.parseDate(discount.to_date).getTime() >= api.parseDate(Arrive).getTime() &&
                        //     discount.listingIDs.includes(Listing.id.toString()) &&
                        //     discount.percent > 0
                        // );
                        var arriveTime_2 = api.parseDate(Arrive).getTime();
                        var departTime_2 = api.parseDate(Depart).getTime();
                        var availableDiscounts = (Listing.discounts || []).filter(function (discount) {
                            if (discount.isLastMinute) {
                                return quoteLib.IsLastMinuteDiscountAppliable(discount, Listing, arriveTime_2, departTime_2);
                            }
                            return api.parseDate(discount.startOn).getTime() <= departTime_2 &&
                                api.parseDate(discount.endOn).getTime() >= arriveTime_2 &&
                                (discount.percent || discount.fixedAmount);
                        });
                        if (availableDiscounts.length == 0) {
                            Listing.quote = quoteLib.CalcPropertyPriceQuote(Listing, Arrive, Depart, 0, null, 'di');
                        }
                        else {
                            Listing.quote = { quotePrice: 999999 };
                            var fridayWeekNumber_1 = 5;
                            var saturdayWeekNumber_1 = 6;
                            availableDiscounts.forEach(function (discount) {
                                var discountEndDate = new Date(new Date().setHours(0, 0, 0, 0));
                                discountEndDate.setDate(today.getDate() + discount.numDays);
                                var startOn = discount.isLastMinute ? api.formatDateYMD(today) : api.formatDateYMD(discount.startOn);
                                var endOn = discount.isLastMinute ? api.formatDateYMD(discountEndDate) : api.formatDateYMD(discount.endOn);
                                var selectedDiscount = {
                                    code: discount.code,
                                    name: 'Platform Discount: ' + discount.code,
                                    isPercentage: discount.percent ? 1 : 0,
                                    amount: discount.percent || discount.fixedAmount,
                                    applicableDays: reservationDays
                                        // what days are discounted
                                        .filter(function (d) { return d >= startOn && d <= endOn; })
                                        // exclude blackout days
                                        .filter(function (d) {
                                        if (!discount.useBlackoutDates) {
                                            return true;
                                        }
                                        var dayOfWeekNumber = new Date(d).getDay();
                                        var isBlackouted = Listing.blackouts.some(function (bl) {
                                            return d >= api.formatDateYMD(bl.from_date) && d <= api.formatDateYMD(bl.to_date) &&
                                                (bl.locationId == Listing.locationid || bl.locationId == 0);
                                        });
                                        if (isBlackouted || discount.weekDaysOnly && (dayOfWeekNumber == fridayWeekNumber_1 || dayOfWeekNumber == saturdayWeekNumber_1)) {
                                            return false;
                                        }
                                        return true;
                                    })
                                        // take the first N days
                                        .slice(0, discount.maxNights)
                                };
                                if (selectedDiscount.applicableDays.length >= 0 && selectedDiscount.applicableDays.length >= discount.minNights) {
                                    var quote = quoteLib.CalcPropertyPriceQuote(Listing, Arrive, Depart, 0, selectedDiscount, 'di');
                                    if (quote.quotePrice < Listing.quote.quotePrice) {
                                        Listing.quote = quote;
                                    }
                                }
                            });
                            var baseQuote = quoteLib.CalcPropertyPriceQuote(Listing, Arrive, Depart, 0, null, 'di');
                            if (Listing.quote.quotePrice === 999999)
                                Listing.quote = baseQuote;
                            Listing.quote.quotePriceWithoutDiscount = baseQuote.quotePrice;
                        }
                    }
                    else {
                        if (Arrive && Depart) {
                            Listing.quote = { quotePrice: noQuotePrice };
                        }
                        else {
                            Listing.quote = quoteLib.CalcPropertyPriceQuote(Listing, Arrive, Depart, 0, null, 'di');
                        }
                    }
                });
                if (sortby && Arrive && Depart && sortby == 'price-ascending') {
                    result = result.sort(function (a, b) {
                        var _a = { qa: a.quote.quotePrice, qb: b.quote.quotePrice }, qa = _a.qa, qb = _a.qb;
                        if (!qa || qa < 0)
                            return 1;
                        if (!qb || qb < 0)
                            return -1;
                        return qa - qb;
                    });
                }
                if (sortby && Arrive && Depart && sortby == 'price-descending') {
                    result = result.sort(function (a, b) {
                        var _a = { qa: a.quote.quotePrice, qb: b.quote.quotePrice }, qa = _a.qa, qb = _a.qb;
                        if (!qa || qa < 0)
                            return 1;
                        if (!qb || qb < 0)
                            return -1;
                        return qb - qa;
                    });
                }
                return result;
            };
        },
        listing: function (state) {
            return function listing(id) {
                for (var _i = 0, _a = state.listings; _i < _a.length; _i++) {
                    var l = _a[_i];
                    if (l.id === id) {
                        return l;
                    }
                }
                return null;
            };
        },
        backgroundLink: function (state) {
            return state.backgroundLink;
        },
        breadcrumbs: function (state) {
            return state.breadcrumbs;
        },
        getMetaInfo: function (state) {
            return state.metaInfo;
        },
        title: function (state) {
            return state.title;
        }
    },
    mutations: {
        setIsLoading: function (state, loading) {
            state.isLoading = loading;
        },
        startLoadingProperty: function (state, data) {
            // data.url = `https://s3.amazonaws.com/nmr-cdn/img/${data.id}/xl/1.jpg`;
            state.loadingImgData = __assign({}, data);
        },
        setTestMode: function (state, value) {
            state.isTestMode = value;
        },
        setBackgroundLink: function (state, backgroundLink) {
            state.backgroundLink = backgroundLink;
        },
        setBreadcrumbs: function (state, breadcrumbs) {
            state.breadcrumbs = breadcrumbs;
        },
        setMetaInfo: function (state, metaInfo) {
            state.metaInfo = metaInfo;
        },
        setTitle: function (state, title) {
            state.title = title;
        },
        setLastSearch: function (state, range) {
            state.lastSearch = {
                start: range.start,
                end: range.end,
                searchType: range.searchType,
            };
        },
        setLocations: function (state, locations) {
            store.state.locations = locations;
        },
        setBlogPosts: function (state, blogPosts) {
            store.state.blogPosts = blogPosts;
        },
        setBlogPost: function (state, blogPost) {
            store.state.blogPost = blogPost;
        },
        setConciergeServices: function (state, conciergeServices) {
            store.state.conciergeServices = conciergeServices;
        },
        setWBCategories: function (state, _a) {
            var listingNum = _a.listingNum, categories = _a.categories;
            if (!store.state.wbCategories)
                store.state.wbCategories = {};
            store.state.wbCategories[listingNum] = categories;
        },
        setListings: function (state, listings) {
            store.state.listings = listings;
        },
        addLocationDetail: function (state, location) {
            store.state.locationDetails[location.id] = location;
        },
        addLocationActivity: function (state, activity) {
            store.state.locationActivities[activity.id] = activity;
        },
        addDiscount: function (state, discount) {
            store.state.discountDetails[discount.id] = discount;
        },
        setHomepageData: function (state, data) {
            store.state.homepageData = data;
        },
        setDiscountsData: function (state, data) {
            store.state.discountsData = data;
        },
        setNewPropertiesData: function (state, data) {
            store.state.newPropertiesData = data;
        },
        setHomepageCombined: function (state, data) {
            store.state.homepageData = data;
            store.state.isHomepageCombined = true;
        },
        setAboutPageData: function (state, data) {
            store.state.aboutpageData = data;
        },
        setListPropertyPageData: function (state, data) {
            store.state.listpropertypageData = data;
        },
        setListPropertyNewData: function (state, data) {
            store.state.listpropertypageNewData = data;
        },
        setCareerPageData: function (state, data) {
            store.state.careerpageData = data;
        },
        setContactPageData: function (state, data) {
            store.state.contactpageData = data;
        },
        setNewState: function (state, data) {
            Object.assign(store.state, data);
        }
    },
    actions: {
        loadListings: function (context) {
            return __awaiter(this, void 0, Promise, function () {
                var randomized_listings;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(!context.state.listings || context.state.listings.length === 0)) return [3 /*break*/, 2];
                            context.commit('setIsLoading', true);
                            return [4 /*yield*/, api.listings()];
                        case 1:
                            randomized_listings = _a.sent();
                            randomized_listings.sort(function () {
                                return 0.5 - Math.random();
                            });
                            randomized_listings.sort(function (a, b) {
                                return a.priority - b.priority;
                            });
                            context.commit('setListings', randomized_listings);
                            context.commit('setIsLoading', false);
                            _a.label = 2;
                        case 2: return [2 /*return*/, context.state.listings];
                    }
                });
            });
        },
        loadLocations: function (context) {
            return __awaiter(this, void 0, Promise, function () {
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            if (!(typeof context.state.locations === 'undefined')) return [3 /*break*/, 2];
                            context.commit('setIsLoading', true);
                            _b = (_a = context).commit;
                            _c = ['setLocations'];
                            return [4 /*yield*/, api.locations()];
                        case 1:
                            _b.apply(_a, _c.concat([_d.sent()]));
                            context.commit('setIsLoading', false);
                            _d.label = 2;
                        case 2: return [2 /*return*/, context.state.locations];
                    }
                });
            });
        },
        loadBlogPosts: function (context) {
            return __awaiter(this, void 0, Promise, function () {
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            if (!(typeof context.state.blogPosts === 'undefined')) return [3 /*break*/, 2];
                            context.commit('setIsLoading', true);
                            _b = (_a = context).commit;
                            _c = ['setBlogPosts'];
                            return [4 /*yield*/, api.blogPosts()];
                        case 1:
                            _b.apply(_a, _c.concat([_d.sent()]));
                            context.commit('setIsLoading', false);
                            _d.label = 2;
                        case 2: return [2 /*return*/, context.state.blogPosts];
                    }
                });
            });
        },
        loadBlogPost: function (context, id) {
            return __awaiter(this, void 0, Promise, function () {
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            context.commit('setIsLoading', true);
                            _b = (_a = context).commit;
                            _c = ['setBlogPost'];
                            return [4 /*yield*/, api.blogPost(id)];
                        case 1:
                            _b.apply(_a, _c.concat([_d.sent()]));
                            context.commit('setIsLoading', false);
                            return [2 /*return*/, context.state.blogPost];
                    }
                });
            });
        },
        loadConciergeServices: function (context) {
            return __awaiter(this, void 0, Promise, function () {
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            context.commit('setIsLoading', true);
                            _b = (_a = context).commit;
                            _c = ['setConciergeServices'];
                            return [4 /*yield*/, api.conciergeServices()];
                        case 1:
                            _b.apply(_a, _c.concat([_d.sent()]));
                            context.commit('setIsLoading', false);
                            return [2 /*return*/, context.state.conciergeServices];
                    }
                });
            });
        },
        loadWBCategories: function (context, _a) {
            var listingNum = _a.listingNum, locationId = _a.locationId;
            return __awaiter(this, void 0, Promise, function () {
                var categoriesGlobal, categoriesLocation, categoriesListing, topicsGlobal, topicsLocation, topicsListing, topics_1, categories;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (!(!context.state.wbCategories || !context.state.wbCategories[listingNum])) return [3 /*break*/, 7];
                            context.commit('setIsLoading', true);
                            return [4 /*yield*/, api.wbGlobalCategories()];
                        case 1:
                            categoriesGlobal = _b.sent();
                            return [4 /*yield*/, api.wbLocationCategories(locationId)];
                        case 2:
                            categoriesLocation = _b.sent();
                            return [4 /*yield*/, api.wbListingCategories(listingNum)];
                        case 3:
                            categoriesListing = _b.sent();
                            return [4 /*yield*/, api.wbGlobalTopics()];
                        case 4:
                            topicsGlobal = _b.sent();
                            return [4 /*yield*/, api.wbLocationTopics(locationId)];
                        case 5:
                            topicsLocation = _b.sent();
                            return [4 /*yield*/, api.wbListingTopics(listingNum)];
                        case 6:
                            topicsListing = _b.sent();
                            topics_1 = __spreadArray(__spreadArray(__spreadArray([], topicsGlobal, true), topicsLocation, true), topicsListing, true);
                            categories = __spreadArray(__spreadArray(__spreadArray([], categoriesGlobal, true), categoriesLocation, true), categoriesListing, true).map(function (c) { return (__assign(__assign({}, c), { topics: topics_1.filter(function (t) { return t.categoryId === c.id; }) })); });
                            context.commit('setWBCategories', { listingNum: listingNum, categories: categories.filter(function (c) { var _a; return ((_a = c.topics) === null || _a === void 0 ? void 0 : _a.length) > 0; }) });
                            context.commit('setIsLoading', false);
                            _b.label = 7;
                        case 7: return [2 /*return*/, context.state.wbCategories[listingNum]];
                    }
                });
            });
        },
        loadLocationBySlug: function (context, _a) {
            var slug = _a.slug;
            return __awaiter(this, void 0, Promise, function () {
                var locations, id, _i, _b, location_1, location;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0: return [4 /*yield*/, context.dispatch('loadLocations')];
                        case 1:
                            locations = (_c.sent());
                            id = -1;
                            for (_i = 0, _b = locations.subPages; _i < _b.length; _i++) {
                                location_1 = _b[_i];
                                if (location_1.slug == slug) {
                                    id = location_1.id;
                                }
                            }
                            if (id === -1) {
                                return [2 /*return*/, null];
                            }
                            return [4 /*yield*/, context.dispatch('loadLocation', { id: id })];
                        case 2:
                            location = _c.sent();
                            // TODO: get rid of hack
                            location.slug = slug;
                            return [2 /*return*/, location];
                    }
                });
            });
        },
        loadLocation: function (context, _a) {
            var id = _a.id;
            return __awaiter(this, void 0, Promise, function () {
                var location, _i, _b, activity;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            location = context.state.locationDetails[id];
                            if (!(typeof location === 'undefined')) return [3 /*break*/, 2];
                            context.commit('setIsLoading', true);
                            return [4 /*yield*/, api.location(id)];
                        case 1:
                            location = _c.sent();
                            context.commit('setIsLoading', false);
                            // TODO: get rid of hack
                            location.id = id;
                            for (_i = 0, _b = location.subPages; _i < _b.length; _i++) {
                                activity = _b[_i];
                                // TODO: get rid of hack
                                activity.slug = activity.slug.replace('activities-single', 'activities');
                                if (!activity.slug.startsWith('/')) {
                                    activity.slug = "/".concat(activity.slug);
                                }
                            }
                            context.commit('addLocationDetail', location);
                            _c.label = 2;
                        case 2: return [2 /*return*/, location];
                    }
                });
            });
        },
        loadLocationActivity: function (context, _a) {
            var locationId = _a.locationId, id = _a.id;
            return __awaiter(this, void 0, Promise, function () {
                var location, activity, _i, _b, subpage;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0: return [4 /*yield*/, context.dispatch('loadLocation', { id: locationId })];
                        case 1:
                            location = _c.sent();
                            activity = context.state.locationActivities[id];
                            if (!(typeof activity === 'undefined')) return [3 /*break*/, 3];
                            context.commit('setIsLoading', true);
                            return [4 /*yield*/, api.locationActivity(locationId, id)];
                        case 2:
                            activity = _c.sent();
                            if (activity.subPages) {
                                for (_i = 0, _b = activity.subPages; _i < _b.length; _i++) {
                                    subpage = _b[_i];
                                    if (!subpage.slug.startsWith('/')) {
                                        subpage.slug = "/".concat(subpage.slug);
                                    }
                                }
                            }
                            context.commit('setIsLoading', false);
                            context.commit('addLocationActivity', activity);
                            _c.label = 3;
                        case 3: return [2 /*return*/, activity];
                    }
                });
            });
        },
        loadHomepage: function (context) {
            return __awaiter(this, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(typeof context.state.homepageData === 'undefined')) return [3 /*break*/, 2];
                            context.commit('setIsLoading', true);
                            return [4 /*yield*/, api.homepage()];
                        case 1:
                            data = _a.sent();
                            context.commit('setHomepageData', data);
                            context.commit('setIsLoading', false);
                            _a.label = 2;
                        case 2: return [2 /*return*/, context.state.homepageData];
                    }
                });
            });
        },
        loadDiscounts: function (context) {
            return __awaiter(this, void 0, void 0, function () {
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            if (!(typeof context.state.discountsData === 'undefined')) return [3 /*break*/, 2];
                            context.commit('setIsLoading', true);
                            _b = (_a = context).commit;
                            _c = ['setDiscountsData'];
                            return [4 /*yield*/, api.discounts()];
                        case 1:
                            _b.apply(_a, _c.concat([_d.sent()]));
                            context.commit('setIsLoading', false);
                            _d.label = 2;
                        case 2: return [2 /*return*/, context.state.discountsData];
                    }
                });
            });
        },
        loadNewProperties: function (context) {
            return __awaiter(this, void 0, void 0, function () {
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            if (!(typeof context.state.newPropertiesData === 'undefined')) return [3 /*break*/, 2];
                            context.commit('setIsLoading', true);
                            _b = (_a = context).commit;
                            _c = ['setNewPropertiesData'];
                            return [4 /*yield*/, api.newProperties()];
                        case 1:
                            _b.apply(_a, _c.concat([_d.sent()]));
                            context.commit('setIsLoading', false);
                            _d.label = 2;
                        case 2: return [2 /*return*/, context.state.newPropertiesData];
                    }
                });
            });
        },
        loadHomepageListings: function (context) {
            return __awaiter(this, void 0, void 0, function () {
                var listings, data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(context.state.isHomepageCombined === false)) return [3 /*break*/, 3];
                            return [4 /*yield*/, loadListings(context)];
                        case 1:
                            listings = _a.sent();
                            return [4 /*yield*/, loadHomepage(context)];
                        case 2:
                            data = _a.sent();
                            data.featured_properties = listings.filter(function (x) { return x.options.featured; });
                            context.commit('setHomepageCombined', data);
                            _a.label = 3;
                        case 3: return [2 /*return*/, context.state.homepageData];
                    }
                });
            });
        },
        loadAboutPage: function (context) {
            return __awaiter(this, void 0, void 0, function () {
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            if (!(typeof context.state.aboutpageData === 'undefined')) return [3 /*break*/, 2];
                            context.commit('setIsLoading', true);
                            _b = (_a = context).commit;
                            _c = ['setAboutPageData'];
                            return [4 /*yield*/, api.aboutpage()];
                        case 1:
                            _b.apply(_a, _c.concat([_d.sent()]));
                            context.commit('setIsLoading', false);
                            _d.label = 2;
                        case 2: return [2 /*return*/, context.state.aboutpageData];
                    }
                });
            });
        },
        loadListPropertyPage: function (context) {
            return __awaiter(this, void 0, void 0, function () {
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            if (!(typeof context.state.listpropertypageData === 'undefined')) return [3 /*break*/, 2];
                            context.commit('setIsLoading', true);
                            _b = (_a = context).commit;
                            _c = ['setListPropertyPageData'];
                            return [4 /*yield*/, api.listpropertypage()];
                        case 1:
                            _b.apply(_a, _c.concat([_d.sent()]));
                            context.commit('setIsLoading', false);
                            _d.label = 2;
                        case 2: return [2 /*return*/, context.state.listpropertypageData];
                    }
                });
            });
        },
        loadListPropertyNew: function (context) {
            return __awaiter(this, void 0, void 0, function () {
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            if (!(typeof context.state.listpropertypageNewData === 'undefined')) return [3 /*break*/, 2];
                            context.commit('setIsLoading', true);
                            _b = (_a = context).commit;
                            _c = ['setListPropertyNewData'];
                            return [4 /*yield*/, api.listpropertyNew()];
                        case 1:
                            _b.apply(_a, _c.concat([_d.sent()]));
                            context.commit('setIsLoading', false);
                            _d.label = 2;
                        case 2: return [2 /*return*/, context.state.listpropertypageNewData];
                    }
                });
            });
        },
        loadCareerPage: function (context) {
            return __awaiter(this, void 0, void 0, function () {
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            if (!(typeof context.state.careerpageData === 'undefined')) return [3 /*break*/, 2];
                            context.commit('setIsLoading', true);
                            _b = (_a = context).commit;
                            _c = ['setCareerPageData'];
                            return [4 /*yield*/, api.careerpage()];
                        case 1:
                            _b.apply(_a, _c.concat([_d.sent()]));
                            context.commit('setIsLoading', false);
                            _d.label = 2;
                        case 2: return [2 /*return*/, context.state.careerpageData];
                    }
                });
            });
        },
        loadContactPage: function (context) {
            return __awaiter(this, void 0, void 0, function () {
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            if (!(typeof context.state.contactpageData === 'undefined')) return [3 /*break*/, 2];
                            context.commit('setIsLoading', true);
                            _b = (_a = context).commit;
                            _c = ['setContactPageData'];
                            return [4 /*yield*/, api.contactpage()];
                        case 1:
                            _b.apply(_a, _c.concat([_d.sent()]));
                            context.commit('setIsLoading', false);
                            _d.label = 2;
                        case 2: return [2 /*return*/, context.state.contactpageData];
                    }
                });
            });
        },
        loadDiscount: function (context, id) {
            return __awaiter(this, void 0, void 0, function () {
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            if (!(typeof context.state.discountDetails[id] === 'undefined')) return [3 /*break*/, 2];
                            context.commit('setIsLoading', true);
                            _b = (_a = context).commit;
                            _c = ['addDiscount'];
                            return [4 /*yield*/, api.discount(id)];
                        case 1:
                            _b.apply(_a, _c.concat([_d.sent()]));
                            context.commit('setIsLoading', false);
                            _d.label = 2;
                        case 2: return [2 /*return*/, context.state.discountDetails[id]];
                    }
                });
            });
        },
        clearState: function (context) {
            var cleanState = {
                isLoading: false,
                loadingImgData: { url: '', title: '', isLoadingListing: false },
                isTestMode: false,
                title: '',
                backgroundLink: '',
                breadcrumbs: [],
                metaInfo: {},
                listings: [],
                listpropertypageNewData: undefined,
                locationDetails: {},
                locationActivities: {},
                discountDetails: {},
                discountsData: undefined,
                newPropertiesData: undefined,
                locations: undefined,
                homepageData: undefined,
                isHomepageCombined: false,
                aboutpageData: undefined,
                contactpageData: undefined,
                listpropertypageData: undefined,
                careerpageData: undefined,
                lastSearch: undefined,
                blogPosts: undefined,
                blogPost: undefined,
                conciergeServices: undefined,
                wbCategories: [],
            };
            context.commit('setNewState', cleanState);
        },
        enableTestMode: function (context) {
            context.commit('setTestMode', true);
        }
    }
};
// function datediff(first: Date, second: Date) {
//   // Take the difference between the dates and divide by milliseconds per day.
//   // Round to nearest whole number to deal with DST.
//   return Math.round((second.valueOf() - first.valueOf())/(1000*60*60*24));
// }
var _a = getStoreAccessors(''), commit = _a.commit, read = _a.read, dispatch = _a.dispatch;
/* Fix for old browsers where function.name isn't well-supported
 * We manually set the "name" of each store function, so that Vuex can identify
 * the objects even in a minified environment
 * */
var collections = [store.getters, store.mutations, store.actions];
for (var _i = 0, collections_1 = collections; _i < collections_1.length; _i++) {
    var collection = collections_1[_i];
    for (var key in collection) {
        var obj = (collection[key]._vuexKey = key);
    }
}
export var location = read(store.getters.location);
export var backgroundLink = read(store.getters.backgroundLink);
export var breadcrumbs = read(store.getters.breadcrumbs);
export var getMetaInfo = read(store.getters.getMetaInfo);
export var title = read(store.getters.title);
export var lastSearch = read(store.getters.lastSearch);
export var setBackgroundLink = commit(store.mutations.setBackgroundLink);
export var setBreadcrumbs = commit(store.mutations.setBreadcrumbs);
export var setMetaInfo = commit(store.mutations.setMetaInfo);
export var setTitle = commit(store.mutations.setTitle);
export var setIsLoading = commit(store.mutations.setIsLoading);
export var startLoadingProperty = commit(store.mutations.startLoadingProperty);
export var setLastSearch = commit(store.mutations.setLastSearch);
export var loadBlogPosts = dispatch(store.actions.loadBlogPosts);
export var loadBlogPost = dispatch(store.actions.loadBlogPost);
export var loadConciergeServices = dispatch(store.actions.loadConciergeServices);
export var loadWBCategories = dispatch(store.actions.loadWBCategories);
export var loadLocations = dispatch(store.actions.loadLocations);
export var loadListings = dispatch(store.actions.loadListings);
export var loadLocationBySlug = dispatch(store.actions.loadLocationBySlug);
export var loadLocation = dispatch(store.actions.loadLocation);
export var loadLocationActivity = dispatch(store.actions.loadLocationActivity);
export var loadHomepage = dispatch(store.actions.loadHomepage);
export var loadHomepageListings = dispatch(store.actions.loadHomepageListings);
export var loadAboutPage = dispatch(store.actions.loadAboutPage);
export var loadContactPage = dispatch(store.actions.loadContactPage);
export var loadListPropertyPage = dispatch(store.actions.loadListPropertyPage);
export var loadListPropertyNew = dispatch(store.actions.loadListPropertyNew);
export var loadDiscount = dispatch(store.actions.loadDiscount);
export var loadDiscounts = dispatch(store.actions.loadDiscounts);
export var loadNewProperties = dispatch(store.actions.loadNewProperties);
export var loadCareerPage = dispatch(store.actions.loadCareerPage);
export var enableTestMode = dispatch(store.actions.enableTestMode);
export var clearState = dispatch(store.actions.clearState);
export default new Vuex.Store(store);
