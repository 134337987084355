export function dateParser(date) {
    if (!date) {
        return null;
    }
    if (typeof date == "string") {
        var parts = date.split("/");
        // MM/DD/YYYY
        var dt = new Date(parseInt(parts[2], 10), parseInt(parts[0], 10) - 1, parseInt(parts[1], 10));
        return dt;
    }
    else {
        if (Array.isArray(date) && date.length >= 1) {
            return dateParser(date[0]);
        }
        else {
            return dt;
        }
    }
}
export function dateFormatter(date) {
    if (!date) {
        return '';
    }
    if (typeof date === 'string') {
        date = new Date(date);
    }
    var d = date;
    var value = "".concat((d.getMonth() + 1), "/").concat(d.getDate(), "/").concat(d.getFullYear());
    return value;
}
export function parseNumber(value) {
    if (typeof value == "string") {
        return parseInt(value, 10);
    }
    if (Array.isArray(value)) {
        return parseNumber(value[0]);
    }
    return parseNumber(value.toString());
}
