var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Vue from 'vue';
import imageSlider from '@/components/image-slider.vue';
import searchBar from '@/components/search-bar.vue';
import breadcrumbs from '@/components/breadcrumbs.vue';
import { siteName } from '@/config';
import * as quoteLib from '@/lib/quote';
import api from "@/api";
var SendInfoBtnText = 'Send to Nomadness Rentals';
export default Vue.extend({
    components: { searchBar: searchBar, imageSlider: imageSlider, breadcrumbs: breadcrumbs },
    props: ['title', 'backgroundLink', 'breadcrumbs'],
    data: function () {
        return {
            inquiryFormVisible: false,
            inquiryResult: undefined,
            formSubmitted: false,
            SubmitBtnText: SendInfoBtnText,
            CanSubmit: true,
            query: {
                name: '',
                email: '',
                phone: '',
                message: '',
                SendWeeklySpecials: true,
                recaptcha: ''
            },
            validation: {},
            t: siteName,
            MainTitle: (siteName == 'mrbo') ? "Welcome to<br>Mammoth Rental By Owner" :
                (siteName == 'steam') ? "Welcome to<br>Steamboat Rental By Owner" :
                    "Exceptional Vacation Rentals",
            maxHeightPage: 450,
            maxHeightHome: 925,
            width: 1925,
            height: 925,
            // isMammothOpen: false,
            // isSteamboatOpen: false,
            // remainingTimeMammoth: "calculating...",
            // remainingTimeSteamboat: "calculating...",
            timer: null
        };
    },
    methods: {
        getTitleCss: function () {
            return this.title && this.title.length > 40 ? "small-full-screen-title" : "";
        },
        getHeaderCss: function () {
            var _a, _b;
            return (_b = (_a = this.$route.matched[1].props) === null || _a === void 0 ? void 0 : _a.default) === null || _b === void 0 ? void 0 : _b.headerCSS;
        },
        disableScroll: function () {
            document.body.style.overflow = this.inquiryFormVisible ? "hidden" : "";
        },
        onResize: function () {
            this.width = window.innerWidth;
            this.height = Math.min(this.maxHeight, window.innerHeight + 120);
            if (this.isHome && this.width > 1050 && this.height < 820)
                this.height = 820;
            if (this.isHome && this.width < 992)
                this.height = 900;
            if (this.isHome && this.width < 768)
                this.height = 1100;
            if (!this.isHome && this.width < 992)
                this.height = 380;
        },
        reload: function () {
            this.$forceUpdate();
            this.onResize();
        },
        CalcDaysUntil: function (d) {
            var n = new Date();
            // get total seconds between the times
            var delta = Math.abs(d.valueOf() - n.valueOf()) / 1000;
            // calculate (and subtract) whole days
            var days = Math.floor(delta / 86400);
            delta -= days * 86400;
            // calculate (and subtract) whole hours
            var hours = Math.floor(delta / 3600) % 24;
            delta -= hours * 3600;
            // calculate (and subtract) whole minutes
            var minutes = Math.floor(delta / 60) % 60;
            delta -= minutes * 60;
            // what's left is seconds
            var seconds = Math.floor(delta) % 60;
            var result = this.fmt2z(days, 'DAY') + this.fmt2z(hours, 'HOUR') + this.fmt2z(minutes, 'MINUTE') + this.fmt2z(seconds, 'SECOND');
            return result;
        },
        fmt2z: function (x, s) {
            return ((x < 10) ? ('0' + (x.toString()) + ' ' + (x != 1 ? s + 'S' : s)) : (x + ' ' + s + 'S')) + ' ';
        },
        // updateCounters() {
        //   var mammothOpeningDay = new Date('09 Nov 2019  09:00:00 GMT-8')
        //   var steamboatOpeningDay = new Date('23 Nov 2019  09:00:00 GMT-6')
        //   var today = new Date()
        //   this.isMammothOpen = today > mammothOpeningDay
        //   this.isSteamboatOpen = today > steamboatOpeningDay
        //   this.remainingTimeMammoth = this.CalcDaysUntil( mammothOpeningDay )
        //   this.remainingTimeSteamboat = this.CalcDaysUntil( steamboatOpeningDay )
        // }
        setSendWeeklySpecials: function () {
            this.$data.contactForm.SendWeeklySpecials = !this.$data.contactForm.SendWeeklySpecials;
        },
        validate: function (p) {
            var v_result = quoteLib.ValidateFormFields(this.$refs);
            // @ts-ignore
            this.validation = v_result.validationErrors;
            var result = v_result.errors.length == 0;
            if (result && p) {
                this.$data.SubmitBtnText = 'Sending Info...';
                this.$data.CanSubmit = false;
            }
            return result;
        },
        onComplete: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (this.validate(true)) {
                        // @ts-ignore
                        this.$refs.invisibleRecaptchaListProperty.execute();
                    }
                    else {
                        alert("Incorrect input");
                    }
                    return [2 /*return*/];
                });
            });
        },
        recaptchaCallback: function (recaptchaToken) {
            return __awaiter(this, void 0, void 0, function () {
                var response, e_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.query.recaptcha = recaptchaToken;
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, api.contact(this.query)];
                        case 2:
                            response = _a.sent();
                            this.SubmitBtnText = SendInfoBtnText;
                            this.CanSubmit = true;
                            if (!response.error) {
                                this.inquiryResult = "Success";
                            }
                            else {
                                this.inquiryResult = response.error;
                            }
                            this.formSubmitted = true;
                            this.query.name = '';
                            this.query.email = '';
                            this.query.phone = '';
                            this.query.message = '';
                            this.query.SendWeeklySpecials = true;
                            alert("Your information has been sent, we will contact you as soon as your message has been reviewed. Thank you");
                            return [3 /*break*/, 4];
                        case 3:
                            e_1 = _a.sent();
                            console.error(e_1);
                            alert(e_1.message);
                            this.SubmitBtnText = SendInfoBtnText;
                            this.CanSubmit = true;
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        },
    },
    computed: {
        isHome: function () {
            return this.$route.name === 'home';
        },
        maxHeight: function () {
            return this.isHome ? this.maxHeightHome : this.maxHeightPage;
        },
        style: function () {
            return "\n        width: ".concat(this.width, "px;\n        height: ").concat(this.height, "px;\n      ");
        },
    },
    watch: {
        '$route': 'reload',
        'inquiryFormVisible': 'disableScroll'
    },
    mounted: function () {
        this.onResize();
        window.addEventListener('resize', this.onResize);
        // var self = this;
        // this.updateCounters()
        // this.timer = setInterval( () => {
        //   self.updateCounters()
        // }, 1000)
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.onResize);
    },
});
