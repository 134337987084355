var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Vue from 'vue';
import store, { loadListings, loadLocations } from '@/store';
import { siteName } from '@/config';
import { dateParser, dateFormatter } from '@/lib/tools';
// @ts-ignore
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
// @ts-ignore
import Calendar from 'v-calendar/lib/components/calendar.umd';
export default Vue.extend({
    name: 'search-bar',
    props: ['detailed', 'lastSearch', 'discount', 'disableLastMinDiscount'],
    components: { DatePicker: DatePicker, Calendar: Calendar },
    data: function () {
        var popperConfig = {
            placement: 'bottom',
            modifiers: [
                {
                    name: 'flip',
                    enabled: false
                }
            ]
        };
        var query = this.$route.query;
        var checkIn = dateParser(query['check-in']);
        var checkOut = dateParser(query['check-out']);
        var searchType = query['search-type'] || 'all';
        var lastMinuteDiscount = searchType === 'discounts' && !this.disableLastMinDiscount;
        var selectedRange = null;
        if (checkIn || checkOut) {
            selectedRange = {
                start: checkIn,
                end: checkOut
            };
        }
        else if (this.lastSearch) {
            selectedRange = {
                start: this.lastSearch.start,
                end: this.lastSearch.end
            };
        }
        var today = new Date(new Date().setHours(0, 0, 0, 0)); // + 24*60*60*1000
        var one_year_from_now = new Date(today.getTime() + 365 * 24 * 60 * 60 * 1000);
        var sortby = query['sortby'] || "default";
        var complex = query['complex'] || "default";
        var property_type = query['property_type'] || "default";
        var seasonal = query['seasonal_vacation'] || "default";
        var min_stay = query['min_stay'] || "default";
        var bedrooms = query['bedrooms'] || 0;
        var pool = query['pool'] == "on";
        var spa = query['spa'] == "on";
        var wifi = query['wifi'] == "on";
        var ac = query['ac'] == "on";
        var pets = query['pets'] == "on";
        var golf = query['golf'] == "on";
        var gondola = query['gondola'] == "on";
        var garage = query['garage'] == "on";
        var IsEcoFriendly = query['IsEcoFriendly'] == "on";
        var village = query['village'] == "on";
        var fishing = query['fishing'] == "on";
        var ski = query['ski'] == "on";
        var discountId = this.discount || query['discount-id'];
        return {
            searchType: searchType,
            lastMinuteDiscount: lastMinuteDiscount,
            popperConfig: popperConfig,
            location: query.location ? query.location : (siteName == 'mrbo') ? 1 : (siteName == 'steam') ? 8 : 0,
            guests: query.guests || 0,
            selectedRange: selectedRange,
            bedrooms: bedrooms,
            price: 0,
            moreFiltersVisible: false,
            today: today,
            one_year_from_now: one_year_from_now,
            complex: complex,
            sortby: sortby,
            property_type: property_type,
            seasonal: seasonal,
            min_stay: min_stay,
            pool: pool,
            spa: spa,
            wifi: wifi,
            ac: ac,
            pets: pets,
            golf: golf,
            gondola: gondola,
            garage: garage,
            IsEcoFriendly: IsEcoFriendly,
            village: village,
            fishing: fishing,
            ski: ski,
            discountId: discountId,
            allComplexNames: [],
            allLocations: [],
            allListings: []
        };
    },
    computed: {
        searchbarClass: function () {
            var classes = [];
            classes.push(this.detailed ? 'searchbar-detailed' : 'searchbar-regular');
            if (this.moreFiltersVisible) {
                classes.push('more-filters-visible');
            }
            if (!siteName.includes('nomadness')) {
                if (this.detailed) {
                    classes.push('searchbar-detailed-no-location');
                }
                else {
                    classes.push('searchbar-no-location');
                }
            }
            return classes;
        }
    },
    methods: {
        toTitleCase: function (str) {
            var word = [];
            for (var _i = 0, _a = str.split(' '); _i < _a.length; _i++) {
                var char = _a[_i];
                if (char.length > 1)
                    if (char[1] != "'")
                        word.push(char[0].toUpperCase() + char.slice(1).toLowerCase());
                    else
                        word.push(char[0].toLowerCase() + "'" + char[2].toUpperCase() + char.slice(3).toLowerCase());
                else
                    word.push(char.toUpperCase());
            }
            return word.join(' ');
        },
        updateHeader: function () {
            return __awaiter(this, void 0, void 0, function () {
                var allListings, allLocations, allLocationsSubPages, routeLocationSlug;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, loadListings(store)];
                        case 1:
                            allListings = _a.sent();
                            return [4 /*yield*/, loadLocations(store)];
                        case 2:
                            allLocations = _a.sent();
                            allLocationsSubPages = allLocations.subPages;
                            routeLocationSlug = this.$route.params.locationSlug;
                            if (routeLocationSlug) {
                                this.location = routeLocationSlug !== "all" ? allLocationsSubPages.find(function (l) { return l.slug == routeLocationSlug; }).id : 0;
                            }
                            this.allComplexNames = Array.from(new Set(allListings.map(function (x) { return _this.toTitleCase(x.options.complexName.trim()); }).filter(function (x) { return x.length > 1; }).sort()));
                            this.allLocations = allLocationsSubPages;
                            this.allListings = allListings;
                            return [2 /*return*/];
                    }
                });
            });
        },
        toggleMoreFilters: function () {
            this.moreFiltersVisible = !this.moreFiltersVisible;
        },
        dateFormatter: function (date) {
            return dateFormatter(date);
        },
        onSubmit: function () {
            this.moreFiltersVisible = false;
            var location = '' + this.location;
            var guests = '' + (this.guests === 0 ? 2 : this.guests);
            var bedrooms = '' + (this.bedrooms === 0 ? '' : this.bedrooms);
            var price = '' + (this.price === 0 ? '' : this.price);
            var selectedRange = this.selectedRange;
            var checkIn = selectedRange ? this.dateFormatter(this.selectedRange.start) : null;
            var checkOut = selectedRange ? this.dateFormatter(this.selectedRange.end) : null;
            var query = {
                guests: guests,
                bedrooms: bedrooms,
                price: price,
                'check-in': checkIn,
                'check-out': checkOut,
                'search-type': this.searchType,
            };
            if (this.detailed) {
                if (this.complex != "default")
                    query.complex = this.complex;
                if (this.sortby != "default")
                    query.sortby = this.sortby;
                if (this.property_type != "default")
                    query.property_type = this.property_type;
                if (this.seasonal != "default")
                    query.seasonal_vacation = this.seasonal;
                if (this.min_stay != "default")
                    query.min_stay = this.min_stay;
                if (this.pool)
                    query.pool = "on";
                if (this.spa)
                    query.spa = "on";
                if (this.wifi)
                    query.wifi = "on";
                if (this.ac)
                    query.ac = "on";
                if (this.pets)
                    query.pets = "on";
                if (this.golf)
                    query.golf = "on";
                if (this.gondola)
                    query.gondola = "on";
                if (this.garage)
                    query.garage = "on";
                if (this.IsEcoFriendly)
                    query.IsEcoFriendly = "on";
                if (this.village)
                    query.village = "on";
                if (this.fishing)
                    query.fishing = "on";
                if (this.ski)
                    query.ski = "on";
                if (this.discountId)
                    query['discount-id'] = this.discountId;
                query['search-type'] = this.lastMinuteDiscount ? "discounts" : "all";
                query.page = 1;
            }
            var routeName = undefined;
            var isLongTermUrl = this.$route.name.includes("term");
            if (selectedRange) {
                var diffTime = Math.abs(selectedRange.end - selectedRange.start);
                var numberOfDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                routeName = numberOfDays > 30 ? "long-term-rentals-search-by-location" : "rentals-search-by-location";
            }
            else {
                routeName = isLongTermUrl ? "long-term-rentals-search-by-location" : "rentals-search-by-location";
            }
            var locationSlug = location !== "0" ? this.allLocations.find(function (x) { return x.id == location; }).slug : "all";
            this.$router.push({
                name: routeName,
                query: query,
                params: { locationSlug: locationSlug, isLongTerm: isLongTermUrl ? "yes" : "no" }
            });
        },
        onChange: function () {
            if (this.detailed) {
                this.onSubmit();
            }
        },
        get_allComplexNames: function () {
            var _this = this;
            if (this.location != 0) {
                return Array.from(new Set(this.allListings.filter(function (p) { return p.locationid == _this.location; }).map(function (x) { return _this.toTitleCase(x.options.complexName.trim()); }).filter(function (x) { return x.length > 1; }).sort()));
            }
            return this.allComplexNames;
        },
        updateLastMinuteCheckbox: function () {
            this.lastMinuteDiscount = this.searchType === 'discounts' && !this.disableLastMinDiscount;
        }
    },
    watch: {
        "disableLastMinDiscount": "updateLastMinuteCheckbox"
    },
    mounted: function () {
        this.updateHeader();
    },
});
